.custom-spin {
    max-height: 100% !important;
}

.custom-spin .ant-spin-dot {
    width: 124px !important;
    height: 124px !important;
    left: 50% !important;
    margin: -70px !important;
}
