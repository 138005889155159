@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }

    .scrollbar::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #f4f4f4;
        border-radius: 10px;
        background-color: transparent;
    }

    .scrollbar::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background: transparent;
    }

    .scrollbar:hover::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px #dfdfdf;
        background-color: #555;
    }

    .scrollbar-white::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px #ffffff;
        border-radius: 10px;
        background-color: transparent;
    }

    .scrollbar-white::-webkit-scrollbar {
        width: 5px;
        background-color: transparent;
    }

    .scrollbar-white::-webkit-scrollbar-thumb {
        background: transparent;
    }

    .scrollbar-white:hover::-webkit-scrollbar-thumb {
        border-radius: 10px;
        -webkit-box-shadow: inset 0 0 6px #dfdfdf;
        background-color: #555;
    }
}

@media only screen and (max-width: 767px) {
    .modal-css {
        max-width: 100% !important;
        position: absolute !important;
        bottom: 0 !important;
        top: unset !important;
        padding-bottom: 0px !important;
    }

    .modal-css .ant-modal-content {
        border-bottom-left-radius: unset !important;
        border-bottom-right-radius: unset !important;
    }
}

* {
    font-family: "Inter", sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

::-webkit-input-placeholder {
    font-family: "Inter", sans-serif;
}
::-moz-placeholder {
    font-family: "Inter", sans-serif;
}
:-ms-input-placeholder {
    font-family: "Inter", sans-serif;
}
::placeholder {
    font-family: "Inter", sans-serif;
}

.slider > .slider-frame > .slider-list {
    width: auto !important;
}

.ant-modal-mask {
    z-index: 1002 !important;
}

.ant-modal-wrap {
    z-index: 1002 !important;
}

.service-item {
    scroll-margin-top: 10px;
}

.ant-drawer {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.ant-modal-close {
    right: 17px;
}

.ant-checkbox .ant-checkbox-inner:after {
    left: 21.5%;
}

.ant-checkbox .ant-checkbox-input {
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

@layer base {
    :root {
        --text-primary: #0078aa;
        --text-secondary: #409abf;
        --text-light: #ffffff;
        --text-light-dark: #555555;
        --text-cart: #767676;
        --text-darker: #3d4654;
        --text-dark: #31343d;
        --text-dark-light: #5a626e;
        --text-error-light: #eb5757;
        --text-error: #ff4d4f;
        --text-error-dark: #ea4335;
        --text-service-name: #3d4654;
        --text-label: #4f4f4f;
        --text-input-checkout: #4f4f4f;
        --text-extra: #585858;
        --text-quick-cart: #58585887;
        --text-nav-item: #3d4654;
        --text-not-found: #c3e7ea;
        --text-download: #484848;

        --bg-primary: #0078aa;
        --bg-secondary: #409abf;
        --bg-error-dark: #ea4335;
        --bg-btn-primary: #0078aa;
        --bg-light-dark: #555555;
        --bg-light: #f1f3f4;
        --bg-block-time: #dddddd;
        --bg-lighter: #f4f4f4;
        --bg-input-checkout: #eee;
        --bg-darker: #3d4654;
        --bg-modal-staffs: #ededed;
        --bg-darkest: #00000080;

        --border-primary: #0078aa;
        --border-secondary: #409abf;
        --border-light: #dfdfdf;
        --border-cart: #767676;
        --border-cart-note: #afafaf;
        --border-lighter: #f0f0f0;
        --border-extra: #eee;
        --border-quick-cart: #ededed;

        --ring-input: #0ea5e9;

        --gradient-primary: #152c47;
        --gradient-secondary: #0078aa;
    }

    .theme-jackport {
        --text-primary: #0078aa;
        --text-secondary: #409abf;
        --text-light: #ffffff;
        --text-light-dark: #555555;
        --text-cart: #767676;
        --text-darker: #3d4654;
        --text-dark: #31343d;
        --text-dark-light: #5a626e;
        --text-error-light: #eb5757;
        --text-error: #ff4d4f;
        --text-error-dark: #ea4335;
        --text-service-name: #3d4654;
        --text-label: #4f4f4f;
        --text-input-checkout: #4f4f4f;
        --text-extra: #585858;
        --text-quick-cart: #58585887;
        --text-nav-item: #3d4654;
        --text-not-found: #c3e7ea;
        --text-download: #484848;

        --bg-primary: #0078aa;
        --bg-secondary: #409abf;
        --bg-error-dark: #ea4335;
        --bg-btn-primary: #0078aa;
        --bg-light-dark: #555555;
        --bg-light: #f1f3f4;
        --bg-block-time: #dddddd;
        --bg-lighter: #f4f4f4;
        --bg-input-checkout: #eee;
        --bg-darker: #3d4654;
        --bg-modal-staffs: #ededed;
        --bg-darkest: #00000080;

        --border-primary: #0078aa;
        --border-secondary: #409abf;
        --border-light: #dfdfdf;
        --border-cart: #767676;
        --border-cart-note: #afafaf;
        --border-lighter: #f0f0f0;
        --border-extra: #eee;
        --border-quick-cart: #ededed;

        --ring-input: #0ea5e9;

        --gradient-primary: #152c47;
        --gradient-secondary: #0078aa;
    }
}

.ant-btn:disabled {
    border: none !important;
    opacity: 0.5;
    color: var(--bg-lighter) !important;
}
